import React, { useState, useEffect, useContext } from "react";
import Link from "next/link";
// import { toast } from "react-toastify";
import GDPR from "../../../public/home/gdpr.webp";
import AuthContext from "../../contexts/AuthContext";
import { useRouter } from "next/router";
import { errorToaster, successToaster } from "../../helpers/utils";
import { subscribeUser, getUserById } from "../../crud/users.crud";
import { useFormik } from "formik";
import * as Yup from "yup";
import Image from "next/future/image";
// import { subscribeUser } from './../crud/konfhub.crud';

const HomeFooter = ({ color = "#572148", fontFamily = "Nunito" }) => {
  const [loading, setLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [userEmail, setUserEmail] = useState("");

  const { route } = useRouter();
  const { signedIn } = useContext(AuthContext);

  const clientType = 1;
  const subscriptionSource =
    route === "/"
      ? 1
      : route === "/resources"
      ? 5
      : route === "/communities"
      ? 4
      : 1;

  useEffect(() => {
    if (signedIn === true) {
      getProfile();
    } else if (
      signedIn === false &&
      (route === "/" || route === "/resources" || route === "/communities")
    ) {
      setTimeout(() => {
        setIsSubscribed(false);
      }, 60000);
    }
  }, [signedIn]);

  const getProfile = async () => {
    try {
      const res = await getUserById();
      if (res.data.email_subscription) setIsSubscribed(true);
      if (
        res.data.email_subscription == false &&
        (route === "/" || route === "/resources" || route === "/communities")
      ) {
        setTimeout(() => {
          setIsSubscribed(false);
        }, 60000);
        setUserEmail(res.data.email_id);
        // return () => {
        //     clearInterval(interval);
        // }
      }
    } catch (err) {
      console.log(err);
      setIsSubscribed(false);
    }
  };

  let formik = useFormik({
    enableReinitialize: true,
    initialValues: { email_id: userEmail },
    validationSchema: Yup.object({
      email_id: Yup.string()
        .required("Email is required")
        .email("Please enter a valid email"),
    }),
    onSubmit: async (values) => {
      try {
        let res = await subscribeUser(
          values.email_id,
          clientType,
          subscriptionSource
        );
        if (res.status === 200) successToaster("Subscribed Successfully");
        if (signedIn === true) {
          getProfile();
        }
        setLoading(false);
        setIsSubscribed(true);
      } catch (err) {
        console.log(err);
        errorToaster("Something went wrong, could not subscribe user");
        setLoading(false);
        setIsSubscribed(false);
      }
    },
  });

  return (
    <>
      <div className="d-flex flex-grow-1 flex-column justify-content-end">
        <div className="footer-container g-0">
          <div className="row g-0 px-0 mx-0">
            <div className="col-lg-3 d-none d-lg-block py-3 row">
              <div className="offset-2 col-6 mt-4 position-relative">
                <Link href="/">
                  <Image
                    loading="lazy"
                    src="https://konfhub.com/logos/konfhub-logo-purple.svg"
                    className="w-100 h-100"
                    alt="Konfhub"
                    width={91}
                    height={24}
                  />
                </Link>
              </div>
              <div className="offset-xl-2 mt-2 col-xl-8 offset-1 col-10">
                <div className="footer-desc mb-4">
                  The one-stop ticketing & event management platform for online,
                  hybrid and in-person events. In-built features to drive
                  audience to your events & make your events more engaging and
                  effective.
                </div>
              </div>
              <div className="offset-2 col-6">
                <Image
                  loading="lazy"
                  src={GDPR}
                  width="75"
                  height="75"
                  alt="GDPR Certified"
                />
              </div>
            </div>
            <div className="col-lg-9 col-md-12 col-xs-12 py-lg-4 px-lg-4 py-3 px-3">
              <div className="row text-lg-start text-center mx-auto">
                <div className="row">
                  <div className="mobile-responsive d-flex flex-grow-1 flex-row">
                    <div className="col-lg-8 d-flex flex-column mobile-grid"> 
                    <div className="col-lg-12 d-flex flex-row">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-4 d-flex flex-grow-md-1 flex-column my-3 ">
                      <div className="links-title">KonfHub</div>
                      <a
                        href="/pricing"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        Pricing
                      </a>
                      <a
                        href="/features"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        Features
                      </a>
                      <a
                        href="/payments-settlements"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        Payments & Settlements
                      </a>
                      <a
                        href="/ticketing"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        Comprehensive Ticketing
                      </a>
                      <a
                        href="/whitelabeling"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        White Labeling
                      </a>
                      <a
                        href="/usecases"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        Use Cases
                      </a>
                      <a
                        href="/developers"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        For Developers
                      </a>
                      <a
                        href="/stripe-connect"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        Stripe Connect
                      </a>
                      <a
                        href="/tap-payments"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        Tap Payments
                      </a>
                      <a
                        href="/logos"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        Brand Assets
                      </a>
                      {/* <a href="/about" className="footer-link">Features</a>
                                            <a href="/pricing" className="footer-link">Use Cases</a>
                                            <a href="/pricing" className="footer-link">Ticket Price Calculator</a> */}
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-4 d-flex flex-grow-md-1 flex-column my-3 ">
                      <div className="links-title">Legal & Policies</div>
                      <a
                        href="/code-of-conduct"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        Code of Conduct
                      </a>
                      <a
                        href="/privacy-policy"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        Privacy Policy
                      </a>
                      {/* <a href="https://konfhub.com/cookie-policy" target="_blank" rel="noreferrer" className="footer-link">Cookie Policy</a> */}
                      {/* <a href="/disclaimer" className="footer-link">Disclaimer</a> */}
                      <a
                        href="/terms-and-conditions"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        Terms & Conditions
                      </a>
                      <a
                        href="/responsible-disclosure"
                        target="_blank"
                        className="footer-link"
                      >
                        {" "}
                        Responsible Disclosure
                      </a>
                      <a
                        href="/cancellation-refund-policy"
                        target="_blank"
                        className="footer-link"
                      >
                        Cancellation & Refund Policy
                      </a>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-4 d-flex flex-grow-md-1 flex-column my-3 ">
                      <div>
                        <div className="links-title">Solutions</div>
                        {/* <a href="https://konfhub.medium.com" target="_blank" rel="noreferrer" className="footer-link">All-inclusive Dashboard</a> */}
                        {/* <a href="https://www.youtube.com/playlist?list=PL80YlDTccZmIeQChHjf_2lR_UHeowZXaL" target="_blank" rel="noreferrer" className="footer-link">Check-in App</a> */}
                        {/* <a href="/assets" className="footer-link">Lead Capture App</a> */}
                        <a
                          href="https://cert.konfhub.com/"
                          className="footer-link"
                        >
                          CertHub
                        </a>
                        <a
                          href="https://quiz.konfhub.com/"
                          className="footer-link"
                        >
                          QuizHub
                        </a>
                        <a
                          href="/checkinapp"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          Check-In App
                        </a>
                        <a
                          href="/sponsorapp"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          Sponsor App
                        </a>
                      </div>
                      <div className="mt-4 mobile-model">
                        <div className="links-title">Compare us with</div>
                        <a
                          href="https://blog.konfhub.com/konfhub-vs-townscript"
                          className="footer-link"
                          target="_blank"
                        >
                          Townscript
                        </a>
                        <a
                          href="https://blog.konfhub.com/konfhub-vs-eventbrite"
                          className="footer-link"
                          target="_blank"
                        >
                          Eventbrite
                        </a>
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-12 d-flex flex-row">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-4 d-flex flex-grow-md-1 flex-column my-3">
                      <div className="mobile-model">
                        <div className="links-title">Explore</div>
                        <a
                          href="/events"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          Events
                        </a>
                        <a
                          href="https://blog.konfhub.com/"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          Blogs
                        </a>
                        <a
                          href="https://www.youtube.com/@KonfHubTech"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          Videos
                        </a>
                        <a
                          href="/resources"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          Resources
                        </a>
                        {/* <a href="https://cert.konfhub.com" target="_blank" rel="noreferrer" className="footer-link">How-to-guides</a> */}
                        {/* <a href="https://cert.konfhub.com" target="_blank" rel="noreferrer" className="footer-link">FAQs</a> */}
                      </div>
                      <div className="mobile-model-2">
                        <div className="links-title">Compare us with</div>
                        <a
                          href="https://blog.konfhub.com/konfhub-vs-townscript"
                          className="footer-link"
                          target="_blank"
                        >
                          Townscript
                        </a>
                        <a
                          href="https://blog.konfhub.com/konfhub-vs-eventbrite"
                          className="footer-link"
                          target="_blank"
                        >
                          Eventbrite
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-4 d-flex flex-grow-md-1 flex-column my-3">
                      <div className="mobile-model-2">
                        <div className="links-title">Explore</div>
                        <a
                          href="/events"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          Events
                        </a>
                        <a
                          href="https://blog.konfhub.com/"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          Blogs
                        </a>
                        <a
                          href="https://www.youtube.com/@KonfHubTech"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          Videos
                        </a>
                        <a
                          href="/resources"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          Resources
                        </a>
                      </div>
                      <div className="mobile-model">
                        <div className="links-title">Company</div>
                        <a
                          href="/about-us"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          About Us
                        </a>
                        <a
                          href="/awards"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          Awards
                        </a>
                        <a
                          href="/communities"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          Join our Community
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-4 d-flex flex-grow-md-1 flex-column my-3">
                      <div className="mobile-model-2">
                        <div className="links-title">Company</div>
                        <a
                          href="/about-us"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          About Us
                        </a>
                        <a
                          href="/awards"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          Awards
                        </a>
                        <a
                          href="/communities"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          Join our Community
                        </a>
                      </div>
                      <div className="mobile-model">

                      <div className="links-title">Contact us</div>
                      <a
                        href="mailto:support@konfhub.com"
                        className="footer-link"
                      >
                        Support
                      </a>
                      <a
                        href="https://calendly.com/konfhub/konfhub"
                        target="_blank"
                        className="footer-link"
                      >
                        Schedule a Demo
                      </a>
                      </div>
                    </div>
                    <div className="mobile-model-3 col-lg-4 col-md-6 col-sm-6 col-4 d-flex flex-grow-md-1 flex-column my-3 responsive-view">
                    <div className="links-title">Contact us</div>
                      <a
                        href="mailto:support@konfhub.com"
                        className="footer-link"
                      >
                        Support
                      </a>
                      <a
                        href="https://calendly.com/konfhub/konfhub"
                        target="_blank"
                        className="footer-link"
                      >
                        Schedule a Demo
                      </a>
                    </div>
                    </div>
                    </div>
                    <div className="col-lg-5 d-flex flex-column">
                    <div className="col-lg-11 col-md-12 col-sm-12 col-4 d-flex flex-grow-md-1 flex-column my-3 mobile-view mobile-grid ">
                      <div className="subscription-title links-title">
                        Join Our Newsletter
                      </div>
                      <form>
                        <div className="input-group mt-3 mb-1">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="email@website.com"
                            aria-label="email@website.com"
                            aria-describedby="button-addon2"
                            onChange={(e) =>
                              formik.setFieldValue("email_id", e.target.value)
                            }
                            value={formik.values.email_id}
                            disabled={loading}
                          />
                          <div className="input-group-append d-none d-md-block">
                            <button
                              className="btn btn-header"
                              style={{ background: color }}
                              onClick={() => {
                                setLoading(true);
                                formik.handleSubmit();
                              }}
                              type="submit"
                              disabled={loading}
                            >
                              Subscribe
                            </button>
                          </div>
                          <div className="input-group-append d-block d-md-none">
                            <button
                              className="btn btn-theme btn-subscribe"
                              onClick={() => {
                                setLoading(true);
                                formik.handleSubmit();
                              }}
                              type="submit"
                              disabled={loading}
                            >
                              <i className="fas fa-envelope"></i>
                            </button>
                          </div>
                        </div>
                      </form>
                      <div className="subscription-sub-text mb-3">
                        *No spam “we promise”! ✌️
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-4 d-flex flex-grow-md-1 flew-grow-1 flex-column my-3 mobile-view mobile-grid">
                      <div className="links-title">Recognitions</div>
                      <div className="d-flex flex-lg-row flex-md-row flex-row gap-1 p-1 mb-1 card-direction">
                        <a
                          className="footer-link reco-main p-1"
                        >
                          <img
                            src="/home/badge1.png"
                            alt="capterra"
                            className="reco-image"
                          />
                        </a>
                        <a
                          className="footer-link reco-main p-1"
                        >
                          <img
                            src="/home/badge2.png"
                            alt="FrontRunner"
                            className="reco-image"
                          />
                        </a>
                        <a
                          className="footer-link reco-main p-1"
                        >
                          <img
                            src="/home/badge3.png"
                            alt="categoryLeader"
                            className="reco-image"
                          />
                        </a>
                      </div>
                      <div className="d-flex flex-lg-row flex-md-row flex-row gap-1 p-1 mb-1 card-direction">
                        <a
                          className="footer-link reco-main p-1"
                        >
                          <img
                            src="/home/badge4.png"
                            alt="capterra"
                            className="reco-image"
                          />
                        </a>
                        <a
                          target="_blank"
                          href="https://www.getapp.com/customer-management-software/a/konfhub/"
                          className="footer-link reco-main p-1"
                        >
                          <img
                            src="/home/badge5.png"
                            alt="categoryLeader"
                            className="reco-image-2"
                          />
                        </a>
                        <a
                          target="_blank"
                          href="https://www.softwareadvice.com/ticketing/konfhub-profile/"
                          className="footer-link reco-main p-1"
                        >
                          <img
                            src="/home/badge6.png"
                            alt="FrontRunner"
                            className="reco-image"
                          />
                        </a>
                      </div>
                      <div className="d-flex flex-lg-row flex-md-row flex-row gap-1 p-1 mb-1 card-direction">
                        <a
                          className="footer-link reco-main p-1"
                        >
                          <img
                            src="/home/badge7.png"
                            alt="FrontRunner"
                            className="reco-image-3"
                          />
                        </a>
                        <a
                          target="_blank"
                          href="https://www.capterra.com/p/265126/KonfHub/"
                          className="footer-link reco-main p-1"
                        >
                          <img
                            src="/home/badge8.svg"
                            alt="capterra"
                            className="reco-image"
                          />
                        </a>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
                <div className="row desktop-grid">
                  <div className="mobile-responsive row d-flex flex-grow-1">
                    <div className="col-lg-3 col-md-6 col-sm-6 col-4 d-flex flex-grow-md-1 flex-column my-3 responsive-view">
                      <div className="links-title">KonfHub</div>
                      <a
                        href="/pricing"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        Pricing
                      </a>
                      <a
                        href="/features"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        Features
                      </a>
                      <a
                        href="/payments-settlements"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        Payments & Settlements
                      </a>
                      <a
                        href="/ticketing"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        Comprehensive Ticketing
                      </a>
                      <a
                        href="/whitelabeling"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        White Labeling
                      </a>
                      <a
                        href="/usecases"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        Use Cases
                      </a>
                      <a
                        href="/developers"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        For Developers
                      </a>
                      <a
                        href="/stripe-connect"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        Stripe Connect
                      </a>
                      <a
                        href="/tap-payments"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        Tap Payments
                      </a>
                      <a
                        href="/logos"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        Brand Assets
                      </a>
                      {/* <a href="/about" className="footer-link">Features</a>
                                            <a href="/pricing" className="footer-link">Use Cases</a>
                                            <a href="/pricing" className="footer-link">Ticket Price Calculator</a> */}
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 col-4 d-flex flex-grow-md-1 flex-column my-3 responsive-view">
                      <div className="links-title">Legal & Policies</div>
                      <a
                        href="/code-of-conduct"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        Code of Conduct
                      </a>
                      <a
                        href="/privacy-policy"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        Privacy Policy
                      </a>
                      {/* <a href="https://konfhub.com/cookie-policy" target="_blank" rel="noreferrer" className="footer-link">Cookie Policy</a> */}
                      {/* <a href="/disclaimer" className="footer-link">Disclaimer</a> */}
                      <a
                        href="/terms-and-conditions"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        Terms & Conditions
                      </a>
                      <a
                        href="/responsible-disclosure"
                        target="_blank"
                        className="footer-link"
                      >
                        {" "}
                        Responsible Disclosure
                      </a>
                      <a
                        href="/cancellation-refund-policy"
                        target="_blank"
                        className="footer-link"
                      >
                        Cancellation & Refund Policy
                      </a>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 col-4 d-flex flex-grow-md-1 flex-column my-3 responsive-view">
                      <div>
                        <div className="links-title">Solutions</div>
                        {/* <a href="https://konfhub.medium.com" target="_blank" rel="noreferrer" className="footer-link">All-inclusive Dashboard</a> */}
                        {/* <a href="https://www.youtube.com/playlist?list=PL80YlDTccZmIeQChHjf_2lR_UHeowZXaL" target="_blank" rel="noreferrer" className="footer-link">Check-in App</a> */}
                        {/* <a href="/assets" className="footer-link">Lead Capture App</a> */}
                        <a
                          href="https://cert.konfhub.com/"
                          className="footer-link"
                        >
                          CertHub
                        </a>
                        <a
                          href="https://quiz.konfhub.com/"
                          className="footer-link"
                        >
                          QuizHub
                        </a>
                        <a
                          href="/checkinapp"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          Check-In App
                        </a>
                        <a
                          href="/sponsorapp"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          Sponsor App
                        </a>
                      </div>
                      <div className="mt-4 mobile-model">
                        <div className="links-title">Compare us with</div>
                        <a
                          href="https://blog.konfhub.com/konfhub-vs-townscript"
                          className="footer-link"
                          target="_blank"
                        >
                          Townscript
                        </a>
                        <a
                          href="https://blog.konfhub.com/konfhub-vs-eventbrite"
                          className="footer-link"
                          target="_blank"
                        >
                          Eventbrite
                        </a>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-12 col-sm-12 col-4 d-flex flex-grow-md-1 flex-column my-3 mobile-view mobile-grid ">
                      <div className="subscription-title links-title">
                        Join Our Newsletter
                      </div>
                      <form>
                        <div className="input-group mt-3 mb-1">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="email@website.com"
                            aria-label="email@website.com"
                            aria-describedby="button-addon2"
                            onChange={(e) =>
                              formik.setFieldValue("email_id", e.target.value)
                            }
                            value={formik.values.email_id}
                            disabled={loading}
                          />
                          <div className="input-group-append d-none d-md-block">
                            <button
                              className="btn btn-header"
                              style={{ background: color }}
                              onClick={() => {
                                setLoading(true);
                                formik.handleSubmit();
                              }}
                              type="submit"
                              disabled={loading}
                            >
                              Subscribe
                            </button>
                          </div>
                          <div className="input-group-append d-block d-md-none">
                            <button
                              className="btn btn-theme btn-subscribe"
                              onClick={() => {
                                setLoading(true);
                                formik.handleSubmit();
                              }}
                              type="submit"
                              disabled={loading}
                            >
                              <i className="fas fa-envelope"></i>
                            </button>
                          </div>
                        </div>
                      </form>
                      <div className="subscription-sub-text mb-3">
                        *No spam “we promise”! ✌️
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-4 d-flex flex-grow-md-1 flex-column my-3 responsive-view">
                      <div className="mobile-model">
                        <div className="links-title">Explore</div>
                        <a
                          href="/events"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          Events
                        </a>
                        <a
                          href="https://blog.konfhub.com/"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          Blogs
                        </a>
                        <a
                          href="https://www.youtube.com/@KonfHubTech"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          Videos
                        </a>
                        <a
                          href="/resources"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          Resources
                        </a>
                        {/* <a href="https://cert.konfhub.com" target="_blank" rel="noreferrer" className="footer-link">How-to-guides</a> */}
                        {/* <a href="https://cert.konfhub.com" target="_blank" rel="noreferrer" className="footer-link">FAQs</a> */}
                      </div>
                      <div className="mobile-model-2">
                        <div className="links-title">Compare us with</div>
                        <a
                          href="https://blog.konfhub.com/konfhub-vs-townscript"
                          className="footer-link"
                          target="_blank"
                        >
                          Townscript
                        </a>
                        <a
                          href="https://blog.konfhub.com/konfhub-vs-eventbrite"
                          className="footer-link"
                          target="_blank"
                        >
                          Eventbrite
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-4 d-flex flex-grow-md-1 flex-column my-3 responsive-view">
                      <div className="mobile-model-2">
                        <div className="links-title">Explore</div>
                        <a
                          href="/events"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          Events
                        </a>
                        <a
                          href="https://blog.konfhub.com/"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          Blogs
                        </a>
                        <a
                          href="https://www.youtube.com/@KonfHubTech"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          Videos
                        </a>
                        <a
                          href="/resources"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          Resources
                        </a>
                      </div>
                      <div className="mobile-model">
                        <div className="links-title">Company</div>
                        <a
                          href="/about-us"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          About Us
                        </a>
                        <a
                          href="/awards"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          Awards
                        </a>
                        <a
                          href="/communities"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          Join our Community
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-4 d-flex flex-grow-md-1 flex-column my-3 responsive-view">
                      <div className="mobile-model-2">
                        <div className="links-title">Company</div>
                        <a
                          href="/about-us"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          About Us
                        </a>
                        <a
                          href="/awards"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          Awards
                        </a>
                        <a
                          href="/communities"
                          target="_blank"
                          rel="noreferrer"
                          className="footer-link"
                        >
                          Join our Community
                        </a>
                      </div>
                      <div className="mobile-model">

                      <div className="links-title">Contact us</div>
                      <a
                        href="mailto:support@konfhub.com"
                        className="footer-link"
                      >
                        Support
                      </a>
                      <a
                        href="https://calendly.com/konfhub/konfhub"
                        target="_blank"
                        className="footer-link"
                      >
                        Schedule a Demo
                      </a>
                      </div>
                    </div>
                    <div className="mobile-model-3 col-lg-3 col-md-6 col-sm-6 col-4 d-flex flex-grow-md-1 flex-column my-3 responsive-view">
                    <div className="links-title">Contact us</div>
                      <a
                        href="mailto:support@konfhub.com"
                        className="footer-link"
                      >
                        Support
                      </a>
                      <a
                        href="https://calendly.com/konfhub/konfhub"
                        target="_blank"
                        className="footer-link"
                      >
                        Schedule a Demo
                      </a>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-4 d-flex flex-grow-md-1 flew-grow-1 flex-column my-3 mobile-view">
                      <div className="links-title">Recognitions</div>
                      <div className="d-flex flex-lg-row flex-md-row flex-row gap-1 p-1 mb-1 card-direction">
                        <a
                          className="footer-link p-1"
                        >
                          <img
                            src="/home/badge1.png"
                            alt="capterra"
                            className="reco-image"
                          />
                        </a>
                        <a
                          className="footer-link p-1"
                        >
                          <img
                            src="/home/badge2.png"
                            alt="FrontRunner"
                            className="reco-image"
                          />
                        </a>
                        <a
                          className="footer-link p-1"
                        >
                          <img
                            src="/home/badge3.png"
                            alt="categoryLeader"
                            className="reco-image"
                          />
                        </a>
                      </div>
                      <div className="d-flex flex-lg-row flex-md-row flex-row gap-1 p-1 mb-1 card-direction">
                        <a
                          className="footer-link p-1"
                        >
                          <img
                            src="/home/badge4.png"
                            alt="capterra"
                            className="reco-image"
                          />
                        </a>
                        <a
                          target="_blank"
                          href="https://www.getapp.com/customer-management-software/a/konfhub/"
                          className="footer-link p-1"
                        >
                          <img
                            src="/home/badge5.png"
                            alt="categoryLeader"
                            className="reco-image-2"
                          />
                        </a>
                        <a
                          target="_blank"
                          href="https://www.softwareadvice.com/ticketing/konfhub-profile/"
                          className="footer-link p-1"
                        >
                          <img
                            src="/home/badge6.png"
                            alt="FrontRunner"
                            className="reco-image"
                          />
                        </a>
                      </div>
                      <div className="d-flex flex-lg-row flex-md-row flex-row gap-1 p-1 mb-1 card-direction">
                        <a
                          className="footer-link p-1"
                        >
                          <img
                            src="/home/badge7.png"
                            alt="FrontRunner"
                            className="reco-image-3"
                          />
                        </a>
                        <a
                          target="_blank"
                          href="https://www.capterra.com/p/265126/KonfHub/"
                          className="footer-link p-1"
                        >
                          <img
                            src="/home/badge8.svg"
                            alt="capterra"
                            className="reco-image"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-4 d-flex flex-grow-md-1 flex-column my-3 mobile-view laptop-grid ">
                      <div className="subscription-title links-title">
                        Join Our Newsletter
                      </div>
                      <form>
                        <div className="input-group mt-3 mb-1">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="email@website.com"
                            aria-label="email@website.com"
                            aria-describedby="button-addon2"
                            onChange={(e) =>
                              formik.setFieldValue("email_id", e.target.value)
                            }
                            value={formik.values.email_id}
                            disabled={loading}
                          />
                          <div className="input-group-append d-none d-md-block">
                            <button
                              className="btn btn-header"
                              style={{ background: color }}
                              onClick={() => {
                                setLoading(true);
                                formik.handleSubmit();
                              }}
                              type="submit"
                              disabled={loading}
                            >
                              Subscribe
                            </button>
                          </div>
                          <div className="input-group-append d-block d-md-none">
                            <button
                              className="btn btn-theme btn-subscribe"
                              onClick={() => {
                                setLoading(true);
                                formik.handleSubmit();
                              }}
                              type="submit"
                              disabled={loading}
                            >
                              <i className="fas fa-envelope"></i>
                            </button>
                          </div>
                        </div>
                      </form>
                      <div className="subscription-sub-text mb-3">
                        *No spam “we promise”! ✌️
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-lg-4 mt-0 mx-5 px-xl-4 px-0 galaxy-fold">
            <div className="footer-bottom-container row g-0">
              <div className="col-lg-6 mobile-none col-md-12 copy text-lg-start text-center mt-3 my-lg-4">
                &copy;{" "}
                <a
                  href="https://konfhub.com"
                  target="_blank"
                  rel="noreferrer"
                  className="main-site-link"
                >
                  KonfHub
                </a>{" "}
                | 2020 - {new Date().getFullYear()}. All Rights Reserved
              </div>
              <div className="col-lg-6 desk-none col-md-12 copy text-lg-start text-center mt-3 my-lg-4">
                &copy;{" "}
                <a
                  href="https://konfhub.com"
                  target="_blank"
                  rel="noreferrer"
                  className="main-site-link"
                >
                  KonfHub
                </a>{" "}
                | 2020 - {new Date().getFullYear()}. <br /> All Rights Reserved
              </div>
              <div className="col-lg-6 col-md-12 copy text-lg-end text-center mb-3 my-lg-4">
                <div className="social-desc d-md-inline-block d-block me-md-3">
                  Connect with us on
                </div>
                <a
                  href="https://www.linkedin.com/in/konfhub/"
                  className="social-link  ms-0"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Connect on LinkedIn"
                >
                  <i className="fab fa-linkedin"></i>
                </a>
                <a
                  href="https://www.facebook.com/konfhub/"
                  className="social-link"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Connect on Facebook"
                >
                  <i className="fab fa-facebook-square"></i>
                </a>
                <a
                  href="https://twitter.com/konfhub"
                  className="social-link"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Connect on Twitter"
                >
                  <img className="position-relative" Style="top:-2px" src="/home/twitter-footer.svg" alt="twitter icon" />
                </a>
                <a
                  href="https://konfhub.medium.com"
                  className="social-link "
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Connect on Medium"
                >
                  <i className="fab fa-medium"></i>
                </a>
                <a
                  href="https://konf.me/discord"
                  className="social-link"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Connect on Discord"
                >
                  <i className="fab fa-discord"></i>
                </a>
                <a
                  href="https://www.youtube.com/playlist?list=PL80YlDTccZmIeQChHjf_2lR_UHeowZXaL"
                  className="social-link"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Connect on Youtube"
                >
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .responsive-view {
          width: 20%;
        }
        .laptop-grid {
          display: none !important;
        }
        .reco-main{
          display: flex !important;
          align-items: center;

        }
        .reco-image {
          width: 90px;
        }
        .reco-image-2 {
          width: 90px;
          height: 90px;
          display: flex;
          align-items: center;
        }
        .reco-image-3 {
          width: 90px;
          height: 90px;
        }

        .btn-header {
          color: white;
        }
        .footer-container {
          background: #f6f9fc;
          font-family: ${fontFamily};
          box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.4);
        }

        .footer-container .footer-desc {
          font-weight: 500;
          font-size: 16px;
          line-height: 28px;
        }

        .footer-container .links-title {
          color: ${color};
          font-size: 16px;
          line-height: 28px;
          font-weight: 800;
        }

        .footer-container .subscription-title {
          text-align: left;
          font-weight: 700;
          font-size: 21px;
          line-height: 24px;
          margin-bottom: 8px;
        }

        .footer-container .subscription-sub-text {
          text-align: left;
          font-weight: 400;
          font-size: 15px;
          color: ${color};
        }
        .desk-none {
          display: none !important;
        }

        .footer-link {
          width: fit-content;
        }

        .footer-container a.footer-link {
          display: block;
          color: ${color};
          font-size: 16px;
          line-height: 28px;
          text-decoration: none;
        }

        .footer-container a.main-site-link {
          color: ${color};
          font-size: 14px;
          line-height: 28px;
          text-decoration: none;
        }

        .footer-container a.social-link {
          display: inline-block;
          color: ${color};
          font-size: 20px;
          text-decoration: none;
          margin-left: 25px;
          vertical-align: middle;
        }
        a {
          color: ${color} !important;
        }

        .footer-container a.social-link:first-child {
          margin-left: 0px;
        }

        .footer-container .footer-bottom-container {
          border-top: 0.5px solid ${color};
        }

        .footer-container .copy {
          font-size: 14px;
          line-height: 28px;
          color: ${color};
        }
        .mobile-model-2 {
          display: none;
        }
        .mobile-model-3 {
          display: none !important;
        }
        @media only screen and (min-width: 992px) {
          .desktop-grid {
            display: none !important;
          }
        }
        @media only screen and (max-width: 992px) {
          .responsive-view {
            width: 33%;
          }
          .mobile-grid {
            display: none !important;
          }
          
          .laptop-grid {
            display: block !important;
          }

          .grid-area {
            grid-area: 4 / 1 / 3 / 4;
          }
          .footer-link {
            margin-left: auto;
            margin-right: auto;
          }
        }
        @media only screen and (max-width: 580px) {
          .responsive-view {
            width: 49%;
          }
          .grid-area {
            grid-area: 4 / 1 / 3 / 4;
          }
          .mobile-view {
            width: 100%;
          }
          .mobile-model {
            display: none;
          }
          .mobile-model-2 {
            display: block;
          }
          .mobile-model-3 {
          display: block !important;
          width: 100% !important;
        }
         .mobile-responsive{
          margin-left:10px;
         }
        }
        @media only screen and (max-width: 480px) {
          .responsive-view {
            width: 50%;
          }
          .grid-area {
            grid-area: 4 / 1 / 3 / 4;
          }
          .mobile-view {
            width: 100%;
          }
          .mobile-none {
            display: none !important;
          }
          .desk-none {
            display: block !important;
          }
        }
        @media only screen and (max-width: 390px) {
          .responsive-view {
            width: 50%;
          }
          .flex-row {
            flex-direction: column !important;
          }
          .grid-area {
            grid-area: 4 / 1 / 3 / 4;
          }
          .mobile-view {
            width: 100%;
          }
          .mobile-none {
            display: none !important;
          }
          .desk-none {
            display: block !important;
          }
        }
        @media only screen and (max-width: 355px){
          .galaxy-fold{
            margin-left: 2rem !important;
            margin-right: 2rem !important;
          }
        }
      `}</style>
    </>
  );
};

export default HomeFooter;